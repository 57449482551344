import { GivelifyButton, GivelifyLabel, GivelifyModal } from '@givelify/ui';
import { styled } from '@mui/material';

export const Modal = styled(GivelifyModal)({
    '& .MuiPaper-root': {
        width: '680px',
        maxWidth: '680px',
        paddingBottom: '48px',
    },
    '& .close-button': {
        width: '28px',
        height: '28px',
        '& svg': {
            width: '19.5px',
            height: '19.5px',
        },
    },
});

export const CalendarWrapper = styled('div')(({ theme }) => ({
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '32px',
    [theme.breakpoints.down('tablet')]: {
        marginBottom: '16px',
    },
}));

export const Title = styled(GivelifyLabel)(({ theme }) => ({
    fontSize: '20px',
    lineHeight: '30px',
    fontWeight: 'bold',
    textAlign: 'center',
    marginBottom: '16px',
    [theme.breakpoints.down('tablet')]: {
        fontSize: '18px',
        lineHeight: '26px',
    },
}));

export const DescriptionWrapper = styled('div')({
    marginBottom: '32px',
    textAlign: 'center',
});

export const Description = styled(GivelifyLabel)(({ theme }) => ({
    textAlign: 'center',
    display: 'inline',
    marginRight: '5px',
    '&:last-child': {
        marginRight: 0,
    },
    [theme.breakpoints.down('tablet')]: {
        fontSize: '14px',
        lineHeight: '24px',
    },
}));

export const AddButton = styled(GivelifyButton)(({ theme }) => ({
    width: 'auto',
    height: '48px',
    padding: '8px 24px',
    maxWidth: '288px',
    marginLeft: 'auto',
    marginRight: 'auto',
    [theme.breakpoints.down('tablet')]: {
        height: '40px',
    },
}));

export const DaysLeft = styled(GivelifyLabel)(({ theme }) => ({
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    marginLeft: '-18px',
    marginTop: '8px',
    fontSize: '48px',
    lineHeight: '62px',
    fontWeight: '700',
    zIndex: 1,
    fontFamily: 'MADE Mirage',
    [theme.breakpoints.down('tablet')]: {
        fontSize: '31px',
        lineHeight: '40px',
        marginLeft: '-12px',
        marginTop: '5px',
    },
}));
