/**
 * Constants used for routing
 */
export const PATH = {
    OVERVIEW: '/overview',
    EDIT_PROFILE: '/edit-profile',
    ADMIN: {
        LOGIN: '/admin/login/:token',
    },
    DONEE: {
        LOGIN: '/',
        RESET: '/reset-password',
    },
    DONATIONS: {
        ROOT: '/donations',
        DONATIONS_ACTIVITY: '/donations/donations-activity',
        DONATION_SUMMARY: {
            BY_ENVELOPES: '/donations/donation-summary/by-envelopes',
            BY_DATE: '/donations/donation-summary/by-date',
        },
        BANK_DEPOSITS: '/donations/bank-deposits',
        REFUNDS: '/donations/refunds',
    },
    FUNDRAISING_TOOLS: {
        ROOT: '/fundraising-tools',
        ONLINE_GIVING: '/fundraising-tools/online-giving',
        GIVELIFY_BUTTON: '/fundraising-tools/online-giving/givelify-button',
        SOCIAL_MEDIA_GIVING: '/fundraising-tools/social-media-giving',
        SOCIAL_MEDIA_GIVING_FACEBOOK:
            '/fundraising-tools/social-media-giving/facebook',
        SOCIAL_MEDIA_GIVING_TWITTER:
            '/fundraising-tools/social-media-giving/twitter',
        SOCIAL_MEDIA_GIVING_INSTAGRAM:
            '/fundraising-tools/social-media-giving/instagram',
        SOCIAL_MEDIA_GIVING_YOUTUBE:
            '/fundraising-tools/social-media-giving/youtube',
        SNAP_GIVE: '/fundraising-tools/snap-give',
        GIVELITHON: '/givelithon',
        LAUNCH_GIVELITHON: '/givelithon/:envelopeId',
    },
    GIVELIFY_BUTTON: '/givelify-button/:encodedDoneeId',
    DONORS: {
        ROOT: '/donors',
        PROFILE: '/donors/:encryptedDonorId',
        PROFILE_DONATION_HISTORY: '/donors/:encryptedDonorId?tab=donations',
        PROFILE_MESSAGE_HISTORY: '/donors/:encryptedDonorId?tab=messages',
    },
    SETTINGS: {
        ROOT: '/settings',
        APP_PROFILE: '/settings/app-profile',
        QUICK_GIVE: '/settings/quick-give',
        ENVELOPES: '/settings/envelopes',
        USERS: '/settings/users',
        BANK_INFO: '/settings/banking',
        ORG_INFO: '/settings/organization_info',
        CAMPUSES: '/settings/campuses',
        MISSION_STATEMENT: '/settings/mission-statement',
    },
    REPORTS: {
        ROOT: '/reports',
        GENERATE_REPORTS: '/reports/generate',
        LEGACY_REPORTS: '/reports/legacy',
        LEGACY_REPORTS_DONATIONS: '/reports/legacy/donations',
        LEGACY_REPORTS_DISBURSMENTS: '/reports/legacy/disbursments',
        LEGACY_REPORTS_DONORS: '/reports/legacy/donors',
        LEGACY_REPORTS_IMPORT_FRIENDLY: '/reports/legacy/import-friendly',
        LEGACY_REPORTS_BY_ENVELOPE: '/reports/legacy/by-envelope',
        LEGACY_REPORTS_BY_DONOR: '/reports/legacy/by-donor',
        REPORTS_LIST: '/reports/list',
        REPORT_HISTORY: '/reports/history',
        REPORT_DONATIONS: '/reports/generate/donations',
        REPORT_BANK_DEPOSITS: '/reports/generate/bank-deposits',
        DONATIONS_BY_DONOR: '/reports/generate/donations-by-donors',
        DONATION_BY_ENVELOPE: '/reports/generate/donations-by-envelope',
        REPORT_DONATIONS_LEGACY: '/reports/generate/donation-legacy',
        REPORT_DONORS: '/reports/generate/donors',
        REPORT_DISBURSEMENTS: '/reports/generate/disbursements',
    },
    DATA: {
        INTEGRATIONS_SETUP: '/data/integrations',
        INTEGRATION_CONTENT: '/data/integration/content',
    },
    NOT_FOUND: '/not-found',
};
