import React, { useState, useEffect, useMemo } from 'react';
import { emailRegex } from '@givelify/givelify-ui';
import {
    GivelifyButton,
    GivelifyCheckbox,
    GivelifyLabel,
} from '@givelify/givelify-ui';
import {
    Logger,
    useApiRequest,
    onLogin,
    useTrackingContext,
} from '@givelify/utils';
import { yupResolver } from '@hookform/resolvers/yup';
import { makeStyles, Theme, Typography } from '@material-ui/core';
import { SignInRequest } from 'api/requests/auth';
import {
    SignInResponse,
    mapSignedUserToLocalUser,
} from 'api/services/responses/auth';
import { getAuthService } from 'api/utils/serviceProvider';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getUserEmailLocalStorage } from 'store/user/accessTokenInLocalStorage';
import {
    LOGIN_PAGE_CLICK_SHOW_PASS,
    LOGIN_PAGE_CLICK_SIGN_IN,
    LOGIN_PAGE_CLICK_SIGN_UP,
} from 'utils/clevertapEvents';
import { useRecaptcha } from 'utils/useRecaptcha';
import { webConfig } from 'webConfig';
import { object, string } from 'yup';
import { AppState } from '../../store';
import { setUser, setAccessToken } from '../../store/user/actions';
import { TextInput } from '../inputs/TextInput';

const useStyle = makeStyles((theme: Theme) => ({
    error: {
        marginBottom: theme.spacing(1),
    },
    form: {
        [theme.breakpoints.up('md')]: {
            width: 368,
        },
    },
    actionRow: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        textAlign: 'right',
        paddingRight: 1,
        marginBottom: 33,
        [theme.breakpoints.down('sm')]: {
            marginBottom: 24,
            marginTop: -9,
        },
        '@media (max-width:320px)': {
            flexDirection: 'column',
            alignItems: 'flex-start',
            marginTop: 8,
        },
    },
    checkboxContent: {
        display: 'flex',
        alignItems: 'center',
    },
    checkbox: {
        margin: '0px 12px',
    },
    textField: {
        marginBottom: 12,
        [theme.breakpoints.down('sm')]: {
            marginBottom: 8,
        },
    },
    button: {
        [theme.breakpoints.down('sm')]: {
            height: 36,
        },
    },
    createAccountRow: {
        textAlign: 'center',
        marginTop: 45,
        [theme.breakpoints.down('sm')]: {
            marginTop: 24,
        },
    },
    inlineBlock: {
        display: 'inline-block',
    },
    forgotPassword: {
        fontWeight: 700,
        fontSize: '14px',
        lineHeight: '20px',
    },
    signIn: {
        fontWeight: 800,
        fontSize: '16px',
        lineHeight: '20px',
    },
    signUp: {
        display: 'inline-block',
        fontWeight: 600,
        fontSize: '16px',
        lineHeight: '24px',
    },
}));

interface LoginFormProps {
    handleResetPasswordOpen?: () => void;
}

const LoginForm: React.FC<LoginFormProps> = (props) => {
    const { trackEvent } = useTrackingContext();
    const classes = useStyle();
    const dispatch = useDispatch();
    const authService = useMemo(() => {
        return getAuthService();
    }, []);
    const [loginApi, makeLoginRequest] = useApiRequest<SignInResponse>();
    const [formError, setFormError] = useState<string>('');
    const [passwordVisibility, setPasswordVisibility] = useState(false);
    const { t } = useTranslation();
    const { getRecaptchaToken } = useRecaptcha('signin');
    const user = useSelector((state: AppState) => state.User.user);

    const loginFormText = useMemo(
        () => ({
            text: {
                welcome: t('login.text.welcome'),
                enterPassword: t('login.text.enter_password'),
                requiredEmail: t('login.text.required_email'),
                invalidEmail: t('login.text.invalid_email'),
                requiredPassword: t('login.text.required_password'),
                showPassword: t('login.text.showPassword'),
                newToGivelify: t('login.text.newToGivelify'),
            },
            link: {
                anotherUser: t('login.link.another_user'),
                removeAccount: t('login.link.remove_account'),
                forgotPassword: t('login.link.forgot_password'),
                signUp: t('login.title.signUp'),
            },
            title: {
                email: t('login.title.email'),
                password: t('login.title.password'),
                passwordHolder: t('login.title.password_holder'),
                login: t('login.title.login'),
                signIn: t('login.title.signIn'),
            },
            notRobot: t('labels.notRobot'),
        }),
        [t],
    );

    const form = useForm<SignInRequest>({
        mode: 'onChange',
        resolver: yupResolver(
            object({
                username: string()
                    .required(loginFormText.text.requiredEmail)
                    .matches(emailRegex, loginFormText.text.invalidEmail),
                password: string().required(
                    loginFormText.text.requiredPassword,
                ),
            }),
        ),
        defaultValues: {
            username: getUserEmailLocalStorage(),
        },
    });

    useEffect(() => {
        if (user) {
            onLogin(user, webConfig.appVersion);
            Logger.configureUserData(user.doneeId, user.id);
        }
        // eslint-disable-next-line
    }, [user]);

    const handleSubmit = async (value: SignInRequest) => {
        trackEvent(LOGIN_PAGE_CLICK_SIGN_IN);
        const token = await getRecaptchaToken();
        if (token) {
            makeLoginRequest(
                authService.signIn({ ...value, captchaToken: token }),
            );
        } else {
            setFormError(loginFormText.notRobot);
        }
    };

    const handleSignUpClick = () => {
        trackEvent(LOGIN_PAGE_CLICK_SIGN_UP);
        window.open(webConfig.signupUrl, '_blank');
    };

    useEffect(() => {
        if (loginApi.type === 'REQUEST_ERROR') {
            setFormError(loginApi.error.message);
        }
        if (loginApi.type === 'REQUEST_SUCCESS') {
            Logger.log(
                `User ${loginApi.response.user.id} logged in successfully`,
                {
                    id: loginApi.response.user.id,
                    doneeId: loginApi.response.user.doneeId,
                },
                Logger.LogLevel.INFO,
            );
            dispatch(setUser(mapSignedUserToLocalUser(loginApi.response.user)));
            dispatch(setAccessToken(loginApi.response.accessToken));
        }
    }, [loginApi, dispatch]);

    const {
        formState: { isValid },
    } = form;

    const handlePasswordVisibility = () => {
        trackEvent(LOGIN_PAGE_CLICK_SHOW_PASS);
        setPasswordVisibility((val) => !val);
    };
    const submitForm = form.handleSubmit(handleSubmit);
    return (
        <form className={classes.form} onSubmit={submitForm}>
            <Typography
                className={classes.error}
                color="error"
                id="error-details"
            >
                {formError}
            </Typography>
            <TextInput<SignInRequest>
                ariaLabel="Username input"
                autoFocus={true}
                boxSize="large"
                className={classes.textField}
                defaultValue=""
                formRef={form}
                id="username-input"
                label={
                    form.getValues().username ? loginFormText.title.email : ''
                }
                name="username"
                onEnterSubmit={() => submitForm()}
                placeholder={loginFormText.title.email}
                type="text"
                variant="alt"
            />
            <TextInput<SignInRequest>
                hidePasswordAdornment
                ariaLabel="Password input"
                autoComplete="on"
                boxSize="large"
                className={classes.textField}
                defaultValue=""
                formRef={form}
                id="password-input"
                label={
                    form.getValues().password
                        ? loginFormText.title.password
                        : ''
                }
                name="password"
                onEnterSubmit={() => submitForm()}
                placeholder={loginFormText.title.passwordHolder}
                showPassword={passwordVisibility}
                variant="alt"
            />
            <div className={classes.actionRow}>
                <div className={classes.checkboxContent}>
                    <GivelifyCheckbox
                        ariaLabel="login-show-password-checkbox"
                        checked={passwordVisibility}
                        className={classes.checkbox}
                        name="login-show-password-checkbox"
                        onChange={handlePasswordVisibility}
                    />
                    <GivelifyLabel
                        bold
                        color="neutralGrey"
                        text={loginFormText.text.showPassword}
                        variant="body3"
                    />
                </div>
                <GivelifyButton
                    className={classes.forgotPassword}
                    onClick={props.handleResetPasswordOpen}
                    text={loginFormText.link.forgotPassword}
                    variant="ghost-alt"
                />
            </div>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginBottom: 24,
                }}
            ></div>
            <GivelifyButton
                className={classes.signIn}
                disabled={!isValid}
                isLoading={
                    loginApi.type === 'REQUEST_START' ||
                    loginApi.type === 'REQUEST_SUCCESS'
                }
                size="xLarge"
                text={loginFormText.title.signIn}
                type="submit"
                variant="primary-alt"
                width="100%"
            />
            <div className={classes.createAccountRow}>
                <GivelifyLabel
                    className={classes.inlineBlock}
                    fontWeight={600}
                    text={loginFormText.text.newToGivelify}
                    variant="body1"
                />
                <GivelifyButton
                    className={classes.signUp}
                    onClick={handleSignUpClick}
                    text={`${loginFormText.link.signUp}.`}
                    variant="ghost-alt"
                />
            </div>
        </form>
    );
};

export default LoginForm;
