import React from 'react';
import { CloseInCircleIcon, DesignTokens } from '@givelify/ui';
import {
    BUTTON_IDENTIFIER,
    MODAL_NAME,
    useTrackingContext,
} from '@givelify/utils';
import { useMediaQuery, useTheme } from '@material-ui/core';
import CalendarCountDown from 'assets/calendarCountdown.svg';
import CalendarCountDownMobile from 'assets/calendarCountdownMobile.svg';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import {
    getPrimaryRepNotificationStateLocalStorage,
    setPrimaryRepNotificationStateLocalStorage,
} from 'store/user/accessTokenInLocalStorage';
import RoleTypes from '../../constants/roleTypes';
import { useGasRouterContext } from '../../router/GasRouterProvider';
import { AppState } from '../../store';
import { Features, isFeatureEnabled } from '../../utils/featureGating';
import {
    AddButton,
    CalendarWrapper,
    DaysLeft,
    Description,
    DescriptionWrapper,
    Modal,
    Title,
} from './styles';

const AddPrimaryRepresentativeModalComponent = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { newSeverePrimaryRepVerificationEnabled, donee, mainDoneeId, user } =
        useSelector((state: AppState) => ({
            newSeverePrimaryRepVerificationEnabled: isFeatureEnabled(
                state.System.enabledFeatures,
                Features.PRIMARY_REP_SEVERE_NOTIFICATION,
                false,
            ),
            donee: state.Donee.donee,
            mainDoneeId: state.Donee.campuses
                ? state.Donee.campuses[0].id
                : null,
            user: state.User.user,
        }));
    const { PATH: GAS_ROUTER_PATH } = useGasRouterContext();
    const { trackEventRaw } = useTrackingContext();
    const theme = useTheme();
    const isTablet = useMediaQuery(theme.breakpoints.down('md'));
    const isAdmin = user.role === RoleTypes.ADMIN;
    const isBasicOrFinancial =
        user.role === RoleTypes.BASIC || user.role === RoleTypes.FINANCIAL;
    const validUser =
        mainDoneeId !== null &&
        donee.id === mainDoneeId &&
        (isAdmin || isBasicOrFinancial);
    const hasMid = donee.onboarding.hasMid;
    const hasPrimaryRep =
        donee.onboarding.primaryRepresentative &&
        donee.onboarding.primaryRepresentative.firstName;
    const { t } = useTranslation();
    const [isOpen, setIsOpen] = React.useState(true);
    const copy = React.useMemo(
        () => ({
            titleAdmin: t('addPrimaryRepresentativeModal.titleAdmin'),
            descriptionAdmin: t(
                'addPrimaryRepresentativeModal.descriptionAdmin',
            ),
            buttonAdmin: t('addPrimaryRepresentativeModal.buttonAdmin'),
            titleNonAdmin: t('addPrimaryRepresentativeModal.titleNonAdmin'),
            descriptionNonAdmin: t(
                'addPrimaryRepresentativeModal.descriptionNonAdmin',
            ),
            buttonNonAdmin: t('addPrimaryRepresentativeModal.buttonNonAdmin'),
        }),
        [t],
    );
    const endDate = dayjs.tz('2024-10-01');
    const daysLeft = endDate.diff(dayjs.tz(), 'days');
    const closeHandler = React.useCallback(() => {
        setIsOpen(false);
        setPrimaryRepNotificationStateLocalStorage('true');
    }, []);
    const addHandler = React.useCallback(() => {
        navigate(
            isAdmin
                ? GAS_ROUTER_PATH.SETTINGS.BANK_INFO('rep')
                : GAS_ROUTER_PATH.SETTINGS.USERS(),
        );
        trackEventRaw(
            `Click ${
                isAdmin
                    ? MODAL_NAME.AddPrimaryRepresentative
                    : MODAL_NAME.AddPrimaryRepresentativeNonAdmin
            } Modal <${
                isAdmin ? 'Get Started' : 'Get Started Non Admin'
            }>_${BUTTON_IDENTIFIER}`,
        );
        closeHandler();
    }, [
        GAS_ROUTER_PATH.SETTINGS,
        closeHandler,
        isAdmin,
        navigate,
        trackEventRaw,
    ]);
    const alreadyShown = getPrimaryRepNotificationStateLocalStorage();
    const showModal = React.useMemo(() => {
        return (
            validUser &&
            hasMid &&
            !hasPrimaryRep &&
            newSeverePrimaryRepVerificationEnabled
        );
    }, [
        hasMid,
        hasPrimaryRep,
        newSeverePrimaryRepVerificationEnabled,
        validUser,
    ]);
    React.useEffect(() => {
        if (showModal && isOpen) {
            trackEventRaw(
                `View ${
                    isAdmin
                        ? MODAL_NAME.AddPrimaryRepresentative
                        : MODAL_NAME.AddPrimaryRepresentativeNonAdmin
                } Modal`,
                { page: location.pathname },
            );
        } else if (showModal && !isOpen) {
            trackEventRaw(
                `Close ${
                    isAdmin
                        ? MODAL_NAME.AddPrimaryRepresentative
                        : MODAL_NAME.AddPrimaryRepresentativeNonAdmin
                } Modal`,
                { page: location.pathname },
            );
        }
        //track isOpen and showModal
        //eslint-disable-next-line
    }, [isOpen, showModal]);
    if (!showModal || !isOpen || alreadyShown === 'true') return null;
    return (
        <Modal
            open
            showCloseButton
            CloseButton={<CloseInCircleIcon viewBox="0 0 20 20" />}
            classes={{ closeButton: 'close-button' }}
            data-testid="add-primary-representative-modal"
            onClose={closeHandler}
            trackCloseButton={false}
        >
            <CalendarWrapper>
                <img
                    alt=""
                    src={isTablet ? CalendarCountDownMobile : CalendarCountDown}
                />
                <DaysLeft data-testid="days-left" text={daysLeft} />
            </CalendarWrapper>
            <Title
                data-testid="modal-title"
                text={isAdmin ? copy.titleAdmin : copy.titleNonAdmin}
            />
            <DescriptionWrapper data-testid="modal-description">
                <Description
                    color={DesignTokens.color.textSecondary}
                    text={
                        isAdmin
                            ? copy.descriptionAdmin
                            : copy.descriptionNonAdmin
                    }
                    variant="body1"
                />
                <Description
                    color={DesignTokens.color.textPrimary}
                    text={endDate.format('MMM. D, YYYY.')}
                    variant="body1B"
                />
            </DescriptionWrapper>
            <AddButton
                data-testid="modal-get-started-button"
                onClick={addHandler}
                text={isAdmin ? copy.buttonAdmin : copy.buttonNonAdmin}
                variant="primary"
            />
        </Modal>
    );
};

export const AddPrimaryRepresentativeModal = React.memo(
    AddPrimaryRepresentativeModalComponent,
);
