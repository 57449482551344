import React from 'react';
import { useCaptivePortalContext } from '@givelify/onboarding';
import {
    PAGE_NAME,
    TrackingProvider,
    useTrackingContext,
} from '@givelify/utils';
import { useMediaQuery, useTheme } from '@mui/material';
import { NotificationProvider, useNotification } from 'components';
import { CalendlyCTAButtonProvider } from 'layout/components/header/Dropdowns/CalendlyModal/CalendlyFloatingButton';
import { useSelector } from 'react-redux';
import { matchPath, useLocation, useNavigate } from 'react-router-dom';
import { PATH } from 'router/routes';
import { AppState } from 'store';
import { NAVIGATION_CLICK_LINK } from 'utils/clevertapEvents';
import { Features, isFeatureEnabled } from 'utils/featureGating';
import { LayoutView } from './LayoutView';
import { useMenu } from './useMenu';

const LayoutComponent: React.FC = ({ children }) => {
    const location = useLocation();
    const { trackEvent } = useTrackingContext();
    const { notification, notificationHeight } = useNotification();
    const { showCaptivePortal } = useCaptivePortalContext();
    const {
        newOverviewEnabled,
        newPrimaryRepPageEnabled,
        newDonorDetailsPageEnabled,
    } = useSelector((state: AppState) => ({
        newOverviewEnabled: isFeatureEnabled(
            state.System.enabledFeatures,
            Features.OVERVIEW_NEW_EXPERIENCE,
            false,
        ),
        newPrimaryRepPageEnabled: isFeatureEnabled(
            state.System.enabledFeatures,
            Features.NEW_PRIMARY_REPRESENTATIVE_PAGE,
            false,
        ),
        newDonorDetailsPageEnabled: isFeatureEnabled(
            state.System.enabledFeatures,
            Features.NEW_DONOR_DETAILS_PAGE,
            false,
        ),
    }));
    const [openMoreMenu, setOpenMoreMenu] = React.useState(false);
    const { menuItems, toggleMenuItem } = useMenu();
    const filterdMenuItems = React.useMemo(() => {
        const result = menuItems.map((x) => {
            return {
                ...x,
                nested:
                    x.nested === undefined
                        ? undefined
                        : x.nested.filter((x) => !x.hideInMenu),
            };
        });
        return result;
    }, [menuItems]);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('mobile'));
    const navigate = useNavigate();
    const onClick = React.useCallback(
        (link: string, label: string) => {
            trackEvent(NAVIGATION_CLICK_LINK.replace('TARGET', label));
            navigate(link);
            setOpenMoreMenu(false);
        },
        [trackEvent, setOpenMoreMenu, navigate],
    );
    const onMoreToggle = React.useCallback(() => {
        setOpenMoreMenu(!openMoreMenu);
    }, [openMoreMenu]);
    React.useEffect(() => {
        if (!isMobile) {
            setOpenMoreMenu(false);
        }
    }, [isMobile]);
    //if new pages containe current location path then set new page state
    const isLegacyPage = React.useMemo(() => {
        const newPages = [
            newOverviewEnabled ? PATH.OVERVIEW : undefined,
            PATH.DONORS.ROOT,
            PATH.DONATIONS.DONATION_SUMMARY.BY_ENVELOPES,
            PATH.DONATIONS.DONATION_SUMMARY.BY_DATE,
            PATH.DONATIONS.DONATIONS_ACTIVITY,
            ...(newPrimaryRepPageEnabled ? [PATH.SETTINGS.BANK_INFO] : []),
        ];
        if (
            newDonorDetailsPageEnabled &&
            matchPath(
                {
                    path: PATH.DONORS.PROFILE,
                    end: false,
                },
                location.pathname,
            )
        ) {
            return false;
        }
        return !newPages.includes(location.pathname);
    }, [
        newOverviewEnabled,
        newPrimaryRepPageEnabled,
        newDonorDetailsPageEnabled,
        location.pathname,
    ]);
    const notificationPosition =
        notification?.meta === 'content' ? 'content' : 'main';
    return (
        <LayoutView
            legacyPage={isLegacyPage}
            navigationProps={{
                items: filterdMenuItems,
                onClick: onClick,
                onExpandClick: toggleMenuItem,
                notificationHeight: notificationHeight,
                notificationPosition: notificationPosition,
            }}
            onMoreToggle={onMoreToggle}
            openMoreMenu={openMoreMenu}
            showCaptivePortal={showCaptivePortal}
        >
            {children}
        </LayoutView>
    );
};

export const Layout: React.FC = ({ children }) => {
    return (
        <TrackingProvider
            hideInChild
            pageName={PAGE_NAME.Navigation}
            trackPageVisit={false}
        >
            <NotificationProvider>
                <CalendlyCTAButtonProvider>
                    <LayoutComponent>{children}</LayoutComponent>
                </CalendlyCTAButtonProvider>
            </NotificationProvider>
        </TrackingProvider>
    );
};
